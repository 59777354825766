import * as React from 'react';
import { HeroContainer, HeroImage, HeroMainImage, HeroTextDetail } from './hero.style';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReadMore } from '../masonry-card/masonry-card.style';
import { Link } from 'gatsby';
import hero1 from '../../images/hero1.png';
import hero2 from '../../images/hero2.jpg';
import hero3 from '../../images/hero3.png';
import heroImage from '../../images/logo-aa.png';
SwiperCore.use([Navigation, Pagination, Autoplay]);

interface HeroProps {};

const Hero: React.FunctionComponent<HeroProps> = ({

}) => {
  return (
    <HeroContainer>
      <Swiper
        autoplay={{
          delay: 7000,
        }}
        speed={600}
        spaceBetween={30}
        navigation={{
          prevEl: '.ps-button-prev',
          nextEl: '.ps-button-next',
        }}
        pagination={{
          clickable: true,
        }}
      >
        <HeroMainImage src={heroImage}></HeroMainImage>
        <HeroTextDetail>
          <div className='hero-text'>
            Infinitely charming monastery brewery sim.
          </div>
          <div className="hero-read-more">
            <ReadMore>
              <Link to="#">Check It Out</Link>
            </ReadMore>
          </div>
        </HeroTextDetail>
        <SwiperSlide key="0">
          <HeroContainer>
            <HeroImage src={hero1}></HeroImage>
          </HeroContainer>
        </SwiperSlide>
        <SwiperSlide key="1">
          <HeroContainer>
            <HeroImage src={hero2}></HeroImage>
          </HeroContainer>
        </SwiperSlide>
        <SwiperSlide key="2">
          <HeroContainer>
            <HeroImage src={hero3}></HeroImage>
          </HeroContainer>
        </SwiperSlide>
      </Swiper>
    </HeroContainer>
  )
}

export default Hero;