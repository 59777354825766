import styled from 'styled-components';

export const HeroContainer = styled.div`
  .swiper {
    max-width: calc(76vh / 0.5625);
  }
  @media (max-width: 990px) {
    .swiper-pagination {
      bottom: 6px !important;
    }
  }
`;

export const HeroImage = styled.img`
  width: 100%;
  max-width: calc(76vh / 0.5625);
  max-height: 76vh;
`;

export const HeroTextDetail = styled.div`
  display: none;
  position: absolute;
  bottom: 18px;
  left: 12px;
  width: 30%;
  z-index: 100;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 20px;
  .hero-text {
    font-size: 16px;
    font-weight: 400;
    color: #5e5e5e;
    line-height: 2;
    margin-bottom: 20px;
    text-align: center;
  }
  .hero-read-more {
    text-align: center;
  }
  @media (max-width: 990px) {
    position: static;
    width: 100%;
    padding-bottom: 30px;
  }
`;

export const HeroMainImage = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 25%;
  z-index: 100;
  pointer-events: none;
`;