import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Input from '../input/input';
import Button from '../button/button';
import {
  NewsletterWrapper,
  NewsletterTitle,
  InputWrapper,
  NewsletterInputWrapper,
  ErrorMessage,
  SuccessMessage,
  NewsletterDisclaimer,
} from './newsletter.style';
import { Link } from 'gatsby';

type NewsletterProps = {};

const Newsletter: React.FunctionComponent<NewsletterProps> = ({ ...props }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [listDetails, setListDetails] = useState({});
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e: any) => {
    setName(e.target.value);
    setListDetails({
      FNAME: e.target.value
    })
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    addToMailchimp(email, listDetails) // listFields are optional if you are only capturing the email address.
      .then(({ msg, result }: any) => {
        if (result !== 'success') {
          throw msg;
        }
        setSuccess(msg);
        setError('');
        setEmail('');
      })
      .catch((err: any) => {
        setError(err);
        setSuccess('');
        setEmail('');
      });
  };
  return (
    <NewsletterWrapper {...props} className="newsletter">
      <NewsletterTitle>
        Sign up for our newsletter!
      </NewsletterTitle>

      <NewsletterInputWrapper onSubmit={handleSubmit}>
        {success ? (
          <SuccessMessage>{success} 🙂</SuccessMessage>
        ) : (
          <InputWrapper>
            <Input
              type="fname"
              name="fname"
              placeholder="Name"
              onChange={handleNameChange}
              value={name}
              required
            />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={email}
              required
            />
            <Button className='btn-hammer' title="Subscribe" type="submit" />
          </InputWrapper>
        )}

        {error && (
          <ErrorMessage
            dangerouslySetInnerHTML={{ __html: `<span>*</span>${error}` }}
          />
        )}
      </NewsletterInputWrapper>
      <NewsletterDisclaimer>
      By signing up, you agree to our <Link to="/privacy">Privacy Policy</Link>.
      </NewsletterDisclaimer>
    </NewsletterWrapper>
  );
};

export default Newsletter;
