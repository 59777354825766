import React from 'react';
import Sticky from 'react-stickynode';
import styled, { ThemeProvider } from 'styled-components';
import ScrollToTop from 'react-scroll-up';
import Navbar from './navbar/navbar';
import Newsletter from '../components/newsletter/newsletter';
import Footer from './footer/footer';
import ScrollUpButton from '../components/scroll-up-button/scroll-up-button';
import ResetCss from '../components/reset-css';
import { theme } from '../theme';
import Hero from '../components/hero/hero';

import discordButton2 from '../images/banner-discord-png.png';

const Wrapper = styled.div`
  width: 1170px;
  padding: 30px 15px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 30px 20px;
  }
`;

const NewsletterWrapper = styled.div`
  display: flex;
  column-gap: 22px;
  margin-top: 120px;
  text-align: center;
  @media (max-width: 960px) {
    display: block;
    iframe {
      margin: 90px auto;
      display: block;
    }
  }
`;

type LayoutProps = {
  children: React.ReactNode;
  preWrapperSection?: string;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ children, preWrapperSection }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <ResetCss />
        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <Navbar />
        </Sticky>
        {preWrapperSection && (
          <Hero/>
        )}

        <Wrapper>
          {children}

          
          <NewsletterWrapper>
            <Newsletter />
            <a href="https://discord.gg/eir" target="_blank">
              <img src={discordButton2} width={350} height={250} className="image-link-highlight" />
            </a>
          </NewsletterWrapper>
        </Wrapper>
          <Footer>
            Copyright &copy; {new Date().getFullYear()}
            <a href="https://redq.io/"> RedQ, Inc.</a>
          </Footer>
        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
      </>
    </ThemeProvider>
  );
};

export default Layout;
